import { SVGAttributes } from 'react';

function Logo(props: SVGAttributes<{}>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <title>寸进阅读</title>
      <path fill="#1890F8" d="M0 0h1024v1024H0z" />
      <path
        fill="#F6F15F"
        d="M723.8 260.2c8.8 3.3 18.4 11.5 42.1 36.2 43.3 45.2 60.9 59.1 116.8 92.7 14.7 8.9 29.6 18.4 33 21.1 20.2 16.4 22.6 35.8 6.5 53-6.1 6.5-11.2 9.1-18 9-9.5-.1-14.8-3.5-31.7-20.7-19.6-19.9-25.7-23.9-37.5-25.2-13.6-1.4-28.7 7.2-43.2 24.5-3.6 4.3-31.1 41.9-61.3 83.7s-62.7 86.5-72.2 99.2c-33.6 45-65.3 83.1-81.5 97.7-23.8 21.6-51.1 34.4-86.8 40.8-4.9.8-15.7 1.3-31.5 1.3-22.3-.1-24.9-.3-36.8-2.8-45.1-9.6-88.1-34.3-126.8-72.7-38.2-37.9-60.1-80.5-66-128.3-1.6-13.8-.6-40.3 2-52.9 8.6-40 34.4-79.7 65.6-100.8 21-14.2 43.5-21 69.4-21 30.6 0 56.5 11.1 80 34.3 12.6 12.5 20.9 25.9 25.3 41.3 3.3 11.4 3.2 28.9 0 39.4-7.8 25-25.7 41.4-49.1 45.1-26.9 4.1-50.5-18.6-48.9-47.1.9-15.8 10.4-27.1 24.6-29.5 2.3-.4 4.2-1 4.2-1.4 0-1.1-5.3-8.3-7.6-10.3-3.3-2.9-10.9-5.8-17.1-6.4-15.4-1.6-39.1 9.4-52.3 24.3-20.2 22.8-29.9 52-27 81 4.2 40.7 25.8 76.2 64.1 105 62.2 46.8 118 46.9 168.2.2 16.6-15.4 46.3-51.1 77.4-92.9 15.5-20.9 116.7-160.7 123.1-170.2 4.9-7.1 12-22.4 13.8-29.8 2.4-9.4 1.5-19-2.4-27.4-5-10.7-13.3-17.8-34.4-29.6-18.4-10.3-24.3-18.1-24.2-32 .1-9.3 2.4-15 9.1-22.1 9.2-9.9 17.5-11.7 31.1-6.7z"
      />
      <path
        fill="#DDEEFE"
        d="M393.5 247.5c25.5 3 46.6 8.4 67.9 17.4 48.2 20.4 86.2 57.5 107.4 104.7 19.5 43.4 24.7 93.8 14.6 140.9-4 19.1-15.8 46.6-23.3 54.8-2.3 2.4-6.8 5.9-10.2 7.7-5.3 2.8-6.9 3.2-11.4 2.8-6.7-.6-11.4-3.9-15.5-11.1-4.6-8-4.2-14.2 2.5-40.1 12.7-49 15-72.8 9.6-99.2-10.3-50.3-42-87.4-92.2-108-12.5-5.1-32.9-10.6-46.9-12.5-13.8-2-47.6-1.7-62 .5-64.5 9.8-123.7 44-157.3 90.7-8.1 11.3-18.6 31.9-22.7 44.7-9.3 29.1-10.9 60.2-4.4 92.2 4.9 24.8 15.5 50.1 27.6 66.1 7.6 10 23.2 24.8 34.8 32.9 14.4 10.1 19.4 14.5 23 20 7.7 12 4 28.8-7.8 35.8-6.5 3.7-13.2 3.5-23.8-.9-27-11.2-60.6-44.8-80.2-80.1-11.7-21.1-21.5-53.7-25.8-86.3-2-15.6-2.3-46.5-.5-61 6.3-50.2 26.4-92.4 61-127.9 20.5-20.9 42.9-37.3 70.1-51.1 36.9-18.6 74.1-29.8 113-33.8 9.9-1 40.8-.5 52.5.8z"
      />
    </svg>
  );
}

export default Logo;
