import Box from '@mui/system/Box';

function Home() {
  return (
    <>
      <Box component="p" sx={{ textAlign: 'center', lineHeight: '500px' }}>
        欢迎访问寸进阅读
      </Box>
    </>
  );
}

export default Home;
