import axios, { AxiosRequestConfig } from 'axios';

const http = axios.create({
  baseURL: '/api/app',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

http.interceptors.response.use(
  function (res) {
    return res.data;
  },
  function (res) {
    if (res?.response?.data) {
      return res.response.data;
    }
    return { status: 1, error: res.message || '请求失败' };
  }
);

export function get<
  T extends keyof ApiList | string,
  O = T extends keyof ApiList ? ('query' extends keyof ApiList[T] ? ApiList[T]['query'] : void) : unknown,
  R = T extends keyof ApiList ? ApiList[T]['response'] : unknown
>(url: T, params?: O, config?: AxiosRequestConfig<any>) {
  return http<R, R>({
    method: 'get',
    url,
    params,
    ...config
  });
}

export function post<
  T extends keyof ApiList | string,
  O = T extends keyof ApiList ? ('params' extends keyof ApiList[T] ? ApiList[T]['params'] : void) : any,
  R = T extends keyof ApiList ? ApiList[T]['response'] : unknown
>(url: T, data: O, config?: AxiosRequestConfig<any>) {
  return http<R, R>({
    method: 'post',
    url,
    data,
    ...config
  });
}
