import clsx from 'clsx';
import { parse } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/base';
import { Stack } from '@mui/system';

import { getVipList, transactionWechatOrder } from 'api';
import useStore from 'hooks/useStore';
import device from 'utils/device';
import { initWechat, wechatLogin, wechatPay } from 'utils/wechat';

import classes from './index.module.scss';

function Vip() {
  const store = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [vipList, setVipList] = useState<VipItem[]>([]);
  const [selectId, setSelectId] = useState(0);
  const [loading, setLoading] = useState(true);
  const currentDetail = useMemo(() => {
    return vipList.find((vip) => vip.id === selectId);
  }, [selectId, vipList]);

  const initList = async () => {
    const res = await getVipList();
    if (res.statusCode === 0) {
      setVipList(res.data);
    }
  };

  const login = () => {
    wechatLogin(window.location.href.split('#')[0], 'snsapi_userinfo');
  };

  const handleSubmit = async () => {
    if (!currentDetail?.id) {
      return;
    }
    console.log(window.navigator.userAgent);
    console.log(device);
    if (device.isWeChat && !store.user.isLogin) {
      wechatLogin();
      return;
    }
    if (!store.user.isLogin) {
      alert('请登录');
      return;
    }
    try {
      const res = await transactionWechatOrder(currentDetail.id);
      if (res.statusCode === 0) {
        const payRes = await wechatPay(res.data);
        alert(JSON.stringify(payRes));
      } else {
        alert(JSON.stringify(res));
      }
    } catch (e) {
      alert('支付失败');
    }
  };

  useEffect(() => {
    const params = parse((window.location.search || '').replace(/^\?/, ''));
    if (params.id && !isNaN(Number(params.id))) {
      setSelectId(Number(params.id));
    }

    initList();
    initWechat()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);
  console.log(store);
  return (
    <>
      <Stack component="ul" direction="row" spacing={4} whiteSpace="nowrap">
        {vipList.map((item) => (
          <li
            key={item.id}
            className={clsx(classes.item, {
              [classes.item_active]: item.id === selectId
            })}
            onClick={() => {
              searchParams.set('id', `${item.id}`);
              setSearchParams(searchParams, { replace: true });
              setSelectId(item.id);
            }}
          >
            <h2>{item.name}</h2>
            <div>&yen; {item.amount}</div>
          </li>
        ))}
      </Stack>
      <Button onClick={handleSubmit} disabled={!currentDetail || !store.user.isReady || loading}>
        开通VIP
      </Button>
      <Button onClick={login}>Login</Button>
    </>
  );
}

export default Vip;
