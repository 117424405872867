import { parse } from 'qs';

let status = 0;

const parsed = parse(window.location.search.replace(/^\?/, ''));

const { ByDebug = false } = parsed;

if (ByDebug && status === 0) {
  status = 1;
  import('eruda')
    .then((module) => {
      const eruda = module.default;
      eruda.init();
    })
    .catch(() => {
      status = 0;
    });
}
