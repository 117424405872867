import { RouterProvider } from 'react-router-dom';
import router from 'router';

import store, { StoreContext } from 'store';

export default function App() {
  return (
    <StoreContext.Provider value={store}>
      <RouterProvider router={router} />
    </StoreContext.Provider>
  );
}
