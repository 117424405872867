import Header from 'components/Header';
import { Outlet } from 'react-router-dom';

import { Container } from '@mui/system';

import classes from './index.module.scss';

function Home() {
  return (
    <>
      <Header />
      <Container className={classes.root}>
        <Outlet />
      </Container>
    </>
  );
}

export default Home;
