import Wrapper from 'components/Wrapper';
import Cashier from 'containers/Cashier';
import Home from 'containers/Home';
import Vip from 'containers/Vip';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Wrapper />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: '/cashier',
        element: <Cashier />
      },
      {
        path: '/vip',
        element: <Vip />
      }
    ]
  }
]);

export default router;
