import { get, post } from 'utils/http';

export const getUserInfo = () => get('/account/userinfo');

export const getJsApiTicket = (appid: string, url: string) => get('/wechat/offiaccount/jsapi_ticket', { appid, url });

export const getVipList = () => get('/vip/product/list');

export const getVipDetail = (id: number) => get('/vip/product/detail', { id });

export const transactionWechatOrder = (id: number) => post('/pay/wechat/vip/transactions', { id });
