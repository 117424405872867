import Logo from 'components/icons/Logo';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/system/Box';
import Container from '@mui/system/Container';
import Stack from '@mui/system/Stack';

import classes from './index.module.scss';

function Header() {
  return (
    <header className={classes.root}>
      <Container className={classes.container}>
        <Stack
          component={RouterLink}
          to="/"
          direction="row"
          spacing={1}
          flexWrap="nowrap"
          alignItems="center"
          sx={{ textDecoration: 'none' }}
        >
          <Logo className={classes.logo} />
          <h1 className={classes.sitename}>寸进阅读</h1>
        </Stack>
        <Box>{/* <HeaderSearchInput placeholder="搜索" type="text" /> */}</Box>
      </Container>
    </header>
  );
}

export default Header;
